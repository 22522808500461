export const BaseBackEndAPIsURL = process.env.REACT_APP_BACKEND_API_URL;

export const UsersURL = `${BaseBackEndAPIsURL}users/`;
export const RestPasswordURL = `${UsersURL}rest-password`;




export const BlogsURL = `${BaseBackEndAPIsURL}blogs/`;
export const ListBlogsURL = `${BlogsURL}list-active/`;



export const iOSUrl = "https://apps.apple.com/kw/app/gallerias/id6444364183";
export const APKUrl = "https://play.google.com/store/apps/details?id=com.gallerias_mobile_app";
