import {useEffect, useRef} from "react";

const ProductDeepLinking = (props) => {

    const ref = useRef()
    useEffect(()=>{
        // setTimeout(()=>{
            // ref.current.click();
            // document.getElementById("gallerias_app").click();

            let element = document.createElement("a");
            element.href = "galleriasapp://myapp";
            element.focus();
            element.click();

        // }, 5000)
    }, [])

  return (
      <div style={{textAlign: "center", fontSize: "45px", color: "white", paddingTop: "30px"}}>
          Loading...
          <a ref={ref} id="gallerias_app" href="galleriasapp://myapp">go to appp</a>
      </div>
  )
}


export default ProductDeepLinking;
