import React from "react";
import {Col, Row} from "antd";
import {isValidValue} from "../helper_functions";
import Hide from "./Hide";

const CustomFieldWrapper = (props) => {
    return (
        <Row className={`align-items-center ${props.container_class_name} `}>
            <Hide hide={!isValidValue(props.label)}>
                <Col
                    span={!props.inline && 24}
                    className="fontsize-16  d-inline-flex h-100 align-items-center"
                >
                    <label className={`me-1 fw- font-size-${props.label_size || 14}`}>
                        {props.label}
                        {props.required && <span className="text-danger">*</span>}
                    </label>




                </Col>
            </Hide>

            <Col flex={5} className="font-size-16 h-100 d-flex align-items-end">
                {props.children}
            </Col>

            <Hide hide={!isValidValue(props.helper_text)}>
                <Col xs={24} className="font-size-16 ps-2">
                <span
                    className={`m-0 p-0 ${props.error ? "text-danger" : "text-black-50"} text-danger font-size-12`}>
                        {props.helper_text}
                 </span>
                </Col>
            </Hide>

        </Row>
    );
};

export default CustomFieldWrapper;
