import en from "./en";
import ar from "./ar";
import {isValidValue} from "../global/helper_functions";
import Cookies from 'js-cookie'

const languages = {
    en,
    ar
}

const language_details = {
    en: {dir: "ltr", code: "en"},
    ar: {dir: "rtl", code: "ar"}
}


export var g_language = Cookies.get("language") || "en";
export var g_dir = (language_details[g_language] || {}).dir || "ltr";


export const checkLanguage = (lang) =>{
    return isValidValue(language_details[lang])
}

export const setGLanguage = (lang) => {
    if (!checkLanguage(lang)){
        lang = "en";
    }

    g_language = lang;
    g_dir = language_details[lang].dir;
}

export const getLabel = (label) => {
    return (languages[g_language] || {})[label] || label;
}



export const getLanguageDetails = (lang) => {
    if (language_details[lang]) {
        return language_details[lang]
    }

    return language_details['en']
}
