import React, {useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import Hide from "../../global/components/Hide";
import {isValidValue} from "../../global/helper_functions";
import Logo from "../../assets/img/logo.png"
import DoneIcon from "../../assets/img/done_icon.png"

import {httpRequestWithoutAuth} from "../../global/axios_instance";
import {RestPasswordURL} from "../../global/urls";
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {Alert} from "antd";

const RestPasswordPage = (props) => {
    const url_query = new URLSearchParams(props.location.search);
    const token = url_query.get("token") || "";


    const [data, setData] = useState({token});
    const [helper_text, setHelperText] = useState({});
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const handelFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;
        setData(temp_data);
    }

    const onSubmit = async () => {
        setLoading(true);
        setHelperText({});
        const response = await httpRequestWithoutAuth(RestPasswordURL, "POST", data)

        if (response.status) {
            setDone(true)
        } else {
            setHelperText(response.error_feedback);
        }
        setLoading(false);
    }

    return (
        <Container fluid="lg" className="pt-4">
            <Row className="mt-5 justify-content-center">

                <Col xs={12} md={7} className="text-center pt-2 mt-4 mb-5">
                    <img
                        src={Logo}
                        className="logo"
                    />
                </Col>

                <Col xs={12}/>

                <Hide hide={!done}>
                    <Col xs={12} className="text-center">
                    <img src={DoneIcon}  width={150}/>
                    </Col>
                    <Col xs={12} className="text-center mt-3">
                        <h1>
                            Password Changed!
                        </h1>
                        <h5>
                            Your password has been changed successfully
                        </h5>
                    </Col>
                </Hide>
                <Hide hide={done}>
                    <Col xs={12} md={5}>
                        <CustomTextField
                            {...props}
                            label="New Password"
                            name="new_password"
                            value={data.new_password}
                            onChange={handelFieldChange}
                            helper_text={helper_text.new_password}
                            error={helper_text.new_password !== undefined}
                            type="password"
                        />
                    </Col>

                    <Col xs={12} className="mt-2"/>

                    <Col xs={12} md={5}>
                        <CustomTextField
                            {...props}
                            label="Confirm New Password"
                            name="confirm_new_password"
                            value={data.confirm_new_password}
                            onChange={handelFieldChange}
                            helper_text={helper_text.new_password}
                            error={helper_text.new_password !== undefined}
                            type="password"
                        />
                    </Col>

                    <Col xs={12} className="mt-3"/>

                    <Hide hide={!isValidValue(helper_text.non_field_errors)}>
                        <Col xs={12} md={5}>
                            <Alert
                                className="text-danger"
                                banner
                                description={helper_text.non_field_errors}
                                type="error"
                            />
                        </Col>
                    </Hide>

                    <Col xs={12}/>

                    <Col xs={12} md={5} className="mt-3 d-flex justify-content-end">
                        <Button appearance="primary" className="fw-bold" onClick={onSubmit} loading={loading}>
                            Submit
                        </Button>
                    </Col>
                    <Col xs={12}/>
                </Hide>

            </Row>
        </Container>
    )
}

export default RestPasswordPage;
