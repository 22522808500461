import React from "react";
import {Spin} from "antd";

export const Loading = props =>{
    return(
        <div className="w-100 text-center mt-5 pt-5 pb-2 mb-3 bg-none">
            <Spin size="large" color="red" className="main-color" wrapperClassName="bg-danger" />
            <h1 className="mt-4 main-color">{props.msg || "loading"}</h1>
        </div>
    )
}
