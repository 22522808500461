import {createContext, useEffect, useState} from "react";
import Cookies from 'js-cookie'
import {getLanguageDetails, setGLanguage} from "../localization/main";


const LanguageContext = createContext({});


export const LanguageContextProvider = props => {
    const pre_language_code = Cookies.get("language") || "en";
    const [language, setLanguage] = useState(getLanguageDetails(pre_language_code));

    useEffect(() => {
        let new_lang = pre_language_code;
        let pathname = window.location.pathname.toLocaleLowerCase();

        if (pathname.startsWith("/ar")){
            new_lang = "ar"
        }else{
            new_lang = "en"
        }

        updateLanguage(new_lang)

    }, [])

    const changeLanguage = async () => {
        const lang = language.code === "en"?"ar":"en"
        let pathname = window.location.pathname.toLocaleLowerCase();

        pathname = pathname.replace(`/${language.code}`, "");

        window.location.href = `/${lang}${pathname}`;
        updateLanguage(lang)
    }

    const updateLanguage = (lang) =>{
        setLanguage(getLanguageDetails(lang));
        Cookies.set('language', lang);
        setGLanguage(lang)
    }

    return (
        <LanguageContext.Provider value={{language: language.code, dir: language.dir, changeLanguage}}>
            {props.children}
        </LanguageContext.Provider>
    )
}


export default LanguageContext;
