import "../assets/css/home.css";
import {Col, Container, Row} from "react-bootstrap";
import {getLabel} from "../localization/main";
import CustomHeader from "../global/components/CustomHeader";
import EleganceIcon from "../assets/img/elegance_icon.png";
import ExclusivityIcon from "../assets/img/exclusivity_icon.png";
import PersonalizationIcon from "../assets/img/personalization_icon.png";
import QualityIcon from "../assets/img/quality_icon.png";
import TrustIcon from "../assets/img/trust_icon.png";
import InnovationIcon from "../assets/img/innovation_icon.png";
import CustomerCentricIcon from "../assets/img/customer_centric_icon.png";
import CommunityIconIcon from "../assets/img/community_icon.png";
import OurVisionIcon from "../assets/img/our_vision_icon.png";
import OurMissionIcon from "../assets/img/our_mission_icon.png";
import {Helmet} from "react-helmet";

const AboutUs = (props) => {

    return (
        <>

            <Helmet>
                <title>{getLabel("about_us")}</title>
                <meta
                    property="og:title"
                    name="title"
                    content={getLabel("about_us")}
                    key="title"
                />
                <meta property="og:description" name="description" content={getLabel("default_keywords")} key="description" />
                <meta
                    name="keywords"
                    content={getLabel("default_keywords")}
                />

                <meta property="og:image" content="https://www.galleriasapp.com/static/media/logo.19e18ebe79256369bff6.png"/>
            </Helmet>

            <CustomHeader {...props}/>

            {/*<img src={BackGroundImg} className="vw-100 vh-100 position-absolute z-index-m1"/>*/}
            <Container fluid="lg" className="h-100 pt-4 pb-4 text-center">
                <Row>

                            <Col xs={12}>
                                <div>
                                    <h1 className="main-color font-size-36">
                                        {getLabel("about_us")}
                                    </h1>

                                    <p className="mt-4 fw-bold font-size-18">{getLabel("about_us_p")}</p>
                                </div>
                            </Col>


                            <Col xs={12}>
                                <div className="mt-5"/>
                                    <img src={OurMissionIcon} width={150}/>
                                <h1 className="main-color font-size-32  border-main-color">
                                    {getLabel("our_mission")}
                                </h1>

                                <p className="mt-4 fw-bold font-size-18">{getLabel("our_mission_p")}</p>
                            </Col>


                            <Col xs={12}>
                            <div className="mt-5"/>
                                <img src={OurVisionIcon} width={150}/>
                                <h1 className="main-color font-size-32  border-main-color">
                                    {getLabel("our_vision")}
                                </h1>

                                <p className="mt-4 fw-bold font-size-18">{getLabel("our_vision_p")}</p>
                            </Col>


                            <Col xs={12}>
                                <div className="mt-5"/>

                                <h1 className="main-color font-size-32  border-main-color">
                                    {getLabel("our_values")}
                                </h1>
                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={EleganceIcon} width={75}/>
                                <h1 className="font-size-20 text-center main-color">
                                    <span className="border-bottom border-main-color">
                                    {getLabel("elegance")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("elegance_p")}</p>

                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={ExclusivityIcon} width={75}/>
                                <h1 className="font-size-20 text-white text-center main-color">
                                    <span className="border-bottom border-main-color">
                                     {getLabel("exclusivity")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("exclusivity_p")}</p>

                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={PersonalizationIcon} width={75}/>
                                <h1 className="font-size-20 text-center main-color">
                                    <span className="border-bottom border-main-color">
                                     {getLabel("personalization")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("personalization_p")}</p>

                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={QualityIcon} width={75}/>
                                <h1 className="font-size-20 text-center main-color">
                                    <span className="border-bottom border-main-color">
                                     {getLabel("quality")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("quality_p")}</p>

                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={TrustIcon} width={75}/>
                                <h1 className="font-size-20 text-center main-color">
                                    <span className="border-bottom border-main-color">
                                     {getLabel("trust")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("trust_p")}</p>

                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={InnovationIcon} width={75}/>
                                <h1 className="font-size-20 text-center main-color">
                                    <span className="border-bottom border-main-color">
                                     {getLabel("innovation")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("innovation_p")}</p>

                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={CustomerCentricIcon} width={75}/>
                                <h1 className="font-size-20 text-center main-color">
                                    <span className="border-bottom border-main-color">
                                     {getLabel("customer-centric")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("customer-centric_p")}</p>

                            </Col>

                            <Col xs={12} className="mt-4 text-center">
                                <img src={CommunityIconIcon} width={75}/>
                                <h1 className="font-size-20 text-center main-color">
                                    <span className="border-bottom border-main-color">
                                     {getLabel("community")}
                                    </span>
                                </h1>

                                <p className="mt-2 fw-bold font-size-18">{getLabel("community_p")}</p>

                            </Col>




                </Row>


            </Container>
        </>
    )
}


export default AboutUs;
