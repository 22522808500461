const ar = {
    "gallerias": "جاليرياز",
    "home_header_1": "قم بإهداء أحبائك",
    "with": "مع",
    "home_p_1": "طريقة أفضل لإرسال الهدايا من مجموعة واسعة من السلع الفاخرة",
    "contact_us": "تواصل معنا",
    "about_us": "معلومات عنا",
    "about_us_p": "ارفع مستوى تجربة الهدايا الخاصة بك من خلال تطبيقنا الحصري للهاتف المحمول. اكتشف عالمًا من الفخامة والرقي، حيث تجتمع الفخامة مع الراحة. اكتشف وأرسل الهدايا الرائعة التي تعيد تعريف فن العطاء. قم بتنزيل جاليرياز الآن واستمتع بتجربة مثال الأناقة في متناول يدك.",
    "our_mission": "مهمتنا",
    "our_mission_p": "تتمثل مهمتنا في جاليرياز في إعادة تعريف فن تقديم الهدايا من خلال توفير تجربة تقديم هدايا سلسة وفخمة. نحن نطمح إلى ربط الأفراد بعالم الرفاهية، وتمكينهم من التعبير عن مشاعرهم بسهولة من خلال الهدايا الرائعة والمنسقة بعناية. التزامنا هو أن نجعل كل لحظة استثنائية وكل هدية لا تُنسى، ونضع معيارًا جديدًا لمتعة العطاء.",
    "our_vision": "رؤيتنا",
    "our_vision_p": "تتمثل رؤيتنا في جاليرياز في أن نكون الشركة الرائدة التي لا مثيل لها في مجال تقديم الهدايا الفاخرة، ورمزًا عالميًا للذوق الرفيع والتعبير الباهظ. نحن نتصور مستقبلًا تصبح فيه كل هدية رحلة غير عادية، وتزين كل لفتة طيبة بالأفضل. ومن خلال الابتكار المستمر، والتنظيم الدقيق، والتفاني الذي لا يتزعزع لمستخدمينا، نطمح إلى وضع معايير للأناقة والتفرد في عالم تقديم الهدايا.",
    "our_values": "القيم لدينا",
    "elegance": "الأناقة",
    "elegance_p": "نحن نؤمن بجمال الرقي ونسعى جاهدين لتقديم الهدايا التي تجسد الأناقة.",
    "exclusivity": "الحصرية",
    "exclusivity_p": "نحن ملتزمون بتوفير وتقديم هدايا حصرية وفريدة من نوعها تضع معايير جديدة للرفاهية.",
    "personalization": "التخصيص",
    "personalization_p": "نحن ندرك أهمية اللمسة الشخصية ونوفر الأدوات اللازمة لإنشاء تجارب هدايا مخصصة لا تُنسى.",
    "quality": "الجودة",
    "quality_p": "نحن نعطي الأولوية للجودة، ونضمن أن كل هدية تلبي أعلى معايير الحرفية والتميز.",
    "trust": "الثقة",
    "trust_p": "نحن نبني الثقة من خلال الشفافية والأمان والموثوقية في كل جانب من جوانب تطبيقنا وخدمتنا.",
    "innovation": "الابتكار",
    "innovation_p": "نحن نبتكر باستمرار، ونتبنى أحدث التقنيات لتعزيز تجارب المستخدم والبقاء في المقدمة في مجال الهدايا الفاخرة.",
    "customer-centric": "التركز على العملاء",
    "customer-centric_p": "نحن نضع مستخدمينا في المقام الأول، ونسعى جاهدين لتجاوز توقعاتهم وتقديم خدمة عملاء استثنائية.",
    "community": "مجتمع",
    "community_p": "نحن نعزز مجتمعًا من عشاق الرفاهية الذين يشاركوننا شغفنا بالاحتفال بلحظات الحياة مع أرقى الهدايا.",
    "home": "الرئيسية",
    "support": "الدعم",
    "blog_articles": "المقالات المدونة",
    "no_blog_found": "لم يتم العثور على مقالة",
    "blog": "مقالة",
    "blogs": "المقالات",
    "default_keywords": "جاليرياز، هدية، فاخرة، هدية لأحبائك",
}



export default ar;
