import CustomHeader from "../global/components/CustomHeader";
import {getLabel} from "../localization/main";
import "../assets/css/home.css";
import {Col, Container, Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {httpRequestWithoutAuth} from "../global/axios_instance";
import {ListBlogsURL} from "../global/urls";
import Hide from "../global/components/Hide";
import {Loading} from "../global/components/Loading";
import BlogCard from "../global/components/BlogCard";
import LanguageContext from "../store/LanguageContext";
import {Helmet} from "react-helmet";

const BlogArticles = (props) => {
    const {language} = useContext(LanguageContext);

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const fetchData = async () =>{
            setLoading(true);
            let response = await httpRequestWithoutAuth(ListBlogsURL, "GET");
            if (response.status){
                setBlogs(response.data);
            }
            setLoading(false);
        }

        fetchData();
    },[])


return(
    <>
        <Hide hide={loading}>
        <Helmet>
            <title>{getLabel("blog_articles")}</title>
            <meta
                property="og:title"
                name="title"
                content={getLabel("blog_articles")}
                key="title"
            />
            <meta property="og:description" name="description" content={getLabel("default_keywords")} key="description" />
            <meta
                name="keywords"
                content={getLabel("default_keywords")}
            />

            <meta property="og:image" content="https://www.galleriasapp.com/static/media/logo.19e18ebe79256369bff6.png"/>
        </Helmet>
        </Hide>

        <CustomHeader {...props}/>

        {/*<img src={BackGroundImg} className="vw-100 vh-100 position-absolute z-index-m1"/>*/}
        <Container fluid="lg" className="h-100 pt-4 pb-4">
            <Row>

                <Col xs={12}>
                    <div className="mb-5">
                        <h1 className="main-color font-size-36">
                            {getLabel("blog_articles")}
                        </h1>
                    </div>
                </Col>

                <Hide hide={!loading}>
                    <Loading/>
                </Hide>

                <Hide hide={loading}>

                    <Hide hide={blogs.length > 0}>
                        <h2 className="font-size-32 text-center mt-5 pt-5 main-color">{getLabel("no_blog_found")}</h2>
                    </Hide>

                    <Hide hide={blogs.length === 0}>
                    {blogs.map((item, index)=>(
                    <Col key={item.id} xs={12} md={3}>
                        <BlogCard {...props} language={language} data={item}/>
                    </Col>
                    ))}
                    </Hide>
                </Hide>


            </Row>
        </Container>
        </>
)
}


export default BlogArticles;
