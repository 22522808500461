import "../assets/css/home.css";
import Logo from "../assets/img/logo.png";
import MobileImg from "../assets/img/mobile_img.png";
import {Col, Container, Row} from "react-bootstrap";
import {AppStoreButton, GooglePlayButton} from "react-mobile-app-button";
import useWindowSize from "../global/useWindowSize";
import Hide from "../global/components/Hide";
import {getLabel} from "../localization/main";
import CustomHeader from "../global/components/CustomHeader";
import {Helmet} from "react-helmet";
import {APKUrl, iOSUrl} from "../global/urls";

const Home = (props) => {
    const {inner_width} = useWindowSize();

    return (
        <>

            <Helmet>
                <title>{getLabel("gallerias")}</title>
                <meta
                    property="og:title"
                    name="title"
                    content={getLabel("gallerias")}
                    key="title"
                />
                <meta property="og:description" name="description" content={getLabel("default_keywords")} key="description" />
                <meta
                    name="keywords"
                    content={getLabel("default_keywords")}
                />

                <meta property="og:image" content="https://www.galleriasapp.com/static/media/logo.19e18ebe79256369bff6.png"/>
            </Helmet>

            <CustomHeader {...props} hide_store_btn/>

            {/*<img src={BackGroundImg} className="vw-100 vh-100 position-absolute z-index-m1"/>*/}
            <Container fluid="lg" className="h-100 pt-4 pb-4">
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12}>
                                <img src={Logo} className="w-25 logo mb-1 mb-md-5"/>
                            </Col>

                            <Col xs={12}>
                                <div className="mt-1 mt-md-5">
                                    <p className="font-size-42">
                                        {getLabel("home_header_1")}
                                        <br/>
                                        {getLabel("with")} <span className="fw-bolder">{getLabel("gallerias")}</span>

                                    </p>
                                    <h1 className="font-size-42 mt-4">
                                    </h1>
                                    <p className="mt-4">
                                        {getLabel("home_p_1")}
                                    </p>

                                    <div className="mt-1 mt-md-5">
                                        <div className="justify-content-center d-md-flex">
                                            <div className="d-flex justify-content-center">
                                                <AppStoreButton
                                                    url={iOSUrl}
                                                    theme="dark"
                                                    className="m-2 w-100 d-flex justify-content-center"
                                                />
                                            </div>

                                            <div className="d-flex justify-content-center">
                                                <GooglePlayButton
                                                    url={APKUrl}
                                                    theme="dark"
                                                    className="m-2 w-100 d-flex justify-content-center"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            {/*<Col xs={12} className="mt-5 pt-5 d-flex justify-content-center">*/}


                            {/*    <a className="m-2">*/}
                            {/*        <img src={LinkedinImg} width={30} height={30}/>*/}
                            {/*    </a>*/}

                            {/*    <a className="m-2">*/}
                            {/*        <img src={InstagramImg} width={30} height={30}/>*/}
                            {/*    </a>*/}

                            {/*    <a className="m-2">*/}
                            {/*        <img src={SnapImg} width={30} height={30}/>*/}
                            {/*    </a>*/}

                            {/*</Col>*/}
                        </Row>
                    </Col>


                    <Hide hide={inner_width < 768}>
                    <Col xs={12} md={6} className="text-center ">
                        <img src={MobileImg} className="h-100 mw-100"/>
                    </Col>
                    </Hide>

                </Row>


            </Container>
        </>
    )
}


export default Home;
